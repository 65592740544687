.ui-job-list {
    .provider-name {
        display: inline-block;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .step-success {
        color: var(--color-success);
    }
    .step-error {
        color: var(--color-error);
    }
    .step-warning {
        color: var(--color-warning);
    }
    button.run-job {
        margin-left: 10px;
    }
}