.strategy-operation-groups {
    .group {
        padding: 8px;
        border: 1px solid #ccc;
        margin-bottom: 8px;
        position: relative;
        margin-bottom: 25px;
        &::after {
            content: '或者';
            position: absolute;
            bottom: -24px;
            left: 8px;
        }
        &.last::after {
            content: none;
        }
        .operation {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            .label {
                width: 40px;
                flex-shrink: 0;
            }
            .space {
                width: 16px;
            }
        }
        .ant-select {
            width: 10em;
        }
        .error {
            border: 1px solid red;
        }
        input {
            width: 15em;
        }
        input[type='radio'] {
            width: 15px;
        }
        .ant-picker-input input {
            width: 100%;
        }
    }
}