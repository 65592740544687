
.page-home {
    .status-item {
        display: inline-block;
        margin-left: 10px;
    }
    .status-online {
        color: var(--color-success);
    }
    .status-offline {
        color: var(--color-error);
    }
    >.ant-table-wrapper {
        margin-top: 20px;
    }
}
.page-home-add {
    .qr-code {
        text-align: center;
        height: 200px;
        img {
            width: 200px;
            height: 200px;
        }
    }
}