.add-material {
    .ant-modal-body {
        padding: 0 30px 20px 30px;
        .page-material-button {
            text-align: center;
        }
        .pageImage {
            height: 100px;
        }
    }
}