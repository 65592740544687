.page-login {
    .main {
        margin: 0 auto;
        max-width: 1920px;
        min-width: 1280px;
        position: relative;
        >.background {
            height: 400px;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            img {
                width: 100%;
                height: auto;
            }
        }
        header {
            height: 400px;
            position: relative;
            .header-main {
                max-width: 1200px;
                margin: 0 auto;
                position: relative;
                .logo {
                    margin: 20px 0 0 0;
                    font-size: 26px;
                    line-height: 26px;
                    font-weight: 400;
                    display: flex;
                    width: 100%;
                    align-items: center;
                    img {
                        width: 120px;
                    }
                    span {
                        margin-left: 20px;
                        padding-left: 20px;
                        border-left: 1px solid #fff;
                    }
                }
                .slogan {
                    width: 100%;
                    color: #fff;
                    padding-top: 150px;
                    h2 {
                        color: #fff;
                        font-size: 36px;
                        margin-bottom: 0;
                    }
                    h3 {
                        color: #fff;
                        font-size: 18px;
                    }
                }
                .login-card {
                    width: 300px;
                    position: absolute;
                    background: #fff;
                    padding: 30px;
                    right: 20px;
                    top: 0;
                    h3 {
                        color: #568FE2;
                        font-size: 18px;
                        margin-bottom: 40px;
                        &.subtitle {
                            color: #F0F2F5;
                            font-size: 28px;
                            margin: 0 0 -24px 0;
                        }
                    }
                    .form {
                        margin-bottom: 60px;
                        .form-row {
                            display: flex;
                            margin-bottom: 10px;
                        }
                        button {
                            width: 100%;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
        .features {
            box-shadow: 8px 6px 10px 0px rgba(0, 0, 0, 0.06);
            >.content {
                max-width: 1200px;
                margin: 0 auto;
                padding: 40px 20px;
                display: flex;
                .feature-box {
                    border-right: 2px solid #EEEEEE;
                    margin-right: 40px;
                    padding-right: 40px;
                    flex: 1;
                    &:last-child {
                        border-right: none;
                        margin-right: 0;
                        padding-right: 0;
                    }
                    .title {
                        font-size: 22px;
                        margin-bottom: 20px;
                    }
                    .content {
                        font-size: 14px;
                        color: #5C5C5C;
                    }
                }
            }
        }
        .guide {
            >.title {
                text-align: center;
                font-size: 24px;
                margin-top: 90px;
            }
            .steps {
                max-width: 1200px;
                margin: 50px auto;
                display: flex;
                .step {
                    flex: 1;
                    .image {
                        width: 120px;
                        height: 120px;
                        overflow: hidden;
                        margin: 0 auto;
                        img {
                            max-width: 100%;
                        }
                    }
                    .title {
                        font-size: 18px;
                        text-align: center;
                        margin-top: 20px;
                    }
                    .content {
                        margin-top: 18px;
                        padding: 0 40px;
                        text-align: center;
                    }
                }
            }
            .intro-video {
                text-align: center;
                margin-top: 50px;
                video {
                    width: 640px;
                    height: 352px;
                }
            }
        }
        footer {
            background: #22272D;
            >div {
                max-width: 1200px;
                margin: 0 auto;
                padding: 50px;
                display: flex;
                .card {
                    flex: 1;
                    color: #76787C;
                    h3 {
                        font-size: 18px;
                        color: #fff;
                        a {
                            color: #fff;
                        }
                    }
                    p {
                        a {
                            color: #8D9093;
                            font-size: 14px;
                        }
                    }
                    &.qrcode {
                        width: 130px;
                        flex: 0;
                        img {
                            width: 130px;
                            height: 130px;
                            margin-bottom: 10px;
                        }
                    }
                }
                .add-link {
                    flex: 1.5;
                    padding-right: 30px;
                    p  {
                        margin-bottom: 2px;
                        a {
                            font-size: 12px;
                        }
                        + h3 {
                            margin-top: 8px;
                            font-size: 17px;
                            margin-bottom: 5px;
                        }
                        .link-color {
                            color: #a2acaf;
                        }
                    }
                }
            }
        }
    }
}