.page-provider {
    .ant-breadcrumb {
        margin-bottom: 20px;
    }
    table {
        margin-top: 20px;
        .name {
            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }
        }
    }
}