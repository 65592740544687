.page-admin {
    display: flex;
    .ui-admin-sidebar {
        width: 140px;
        margin: -24px 0 -20px -24px;
        .ant-menu-vertical {
            border-right: none;
        }
    }
    .ui-admin-body {
        flex: 1;
        border-left: 24px solid #F0F2F5;
        min-height: 500px;
        margin: -24px -24px 0 0;
    }
}
.page-admin-user {
    button.add-user {
        margin: 10px;
    }
    .ant-table {
        button {
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }
}
.page-admin-role{
    .title{
        padding: 20px;
        .button{
            margin-left: 20px;
        }
    }
}