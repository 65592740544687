.monitor {
    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .space {
            flex: 1;
        }
        .right {
            >* + * {
                margin-left: 10px;
            }
        }
        .space1 {
            width: 10px;
        }
        .ant-input {
            width: 130px;
        }
    }
    .ant-pagination {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
    }
    .today-cost {
        margin-left: 16px;
        margin-top: 10px;
    }
}