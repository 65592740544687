.strategy-condition-groups {
    .group {
        padding: 8px;
        border: 1px solid #ccc;
        margin-bottom: 8px;
        position: relative;
        margin-bottom: 25px;
        &::after {
            content: '或者';
            position: absolute;
            bottom: -24px;
            left: 8px;
        }
        &.last::after {
            content: none;
        }
        .condition {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            .label {
                width: 40px;
                flex-shrink: 0;
            }
            .space {
                width: 16px;
            }
        }
        .error {
            border: 1px solid red;
        }
        .type {
            width: 8em;
        }
    }
}