.page-material {
    .tag-label {
        margin: 0 10px;
    }
    > .ant-table-wrapper {
        margin-top: 20px;
    }

    .ant-table {
        button {
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }
}