.ui-material-preview-images {
    display: flex;
    .image {
        max-width: 60px;
        height: auto;
        margin: 0 10px 10px 0;
        display: block;
    }
    .popover {
        max-width: 400px;
        height: auto;
    }
}
.ui-material-preview {
    .popover {
        max-width: 400px;
        height: auto;
    }
}