.page-auto-upload {
    .ant-col .ant-list-item.imgBox {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }
    .input-box {
        margin-bottom: 20px;
    }
    .input-box.ant-form-inline .ant-form-item-with-help {
        margin-bottom: 0;
    }
    .ant-list-grid .ant-col > .ant-list-item.list-item-bt {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .ant-col-8, .ant-col-5, .ant-col-11 {
        border: 4px solid #FFF;
        background: #F0F2F5;
        position: relative;
        padding-bottom: 40px;
    }
    .col-header {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        h4 {
            margin: 0;
        }
        span {
            font-size: 14px;
        }
        .div-sort {
            width: 10em;
            display: flex;
            justify-content: space-between;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
        }

    }
    .ant-list-grid .ant-col > .ant-list-item {
        background: #FFF;
        padding: 10px;
    }
    .ant-list-grid .ant-col > .ant-list-item.pageItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        input {
            margin: 0 10px;
            flex: 1;
            border-left: none;
            border-right: none;
            border-top: none;
        }
        .pageId {
            padding: 4px 15px;
            border: 2px solid #d9d9d9;
            border-radius: 4px;
        }
        .pageId.selected {
            border: 2px solid #1890ff;
        }
    }
    .ant-list-split .ant-list-item.selected {
        border: 2px solid #1890ff;
    }
    img, video {
        display: block;
        margin: 0 auto;
        max-height: 100px;
        max-width: 100%;
    }
    .ant-pagination-simple {
        text-align: right;
        margin-bottom: 20px;
        bottom: 0px;
        right: 20px;
        position: absolute;
    }
    table {
        margin-bottom: 16px;
    }
    .ant-upload-list {
        display: none;
    }

}
.chooseAdvertiserModal {
    .selected {
        color: #1890ff;
    }
    .ant-table {
        min-height: 330px;
    }
}